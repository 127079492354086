import React, { useState, useCallback, useEffect } from "react";
import "../style/search-results.css";
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Grow,
  IconButton,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PropertyCard from "./PropertyCard";
import { useParams } from "react-router-dom";
import CustomModal from "../modal/CustomModal";
import PropertyDescriptionHeader from "./PropertyDescriptionHeader";
import Description from "./Description";
import GoogleMapComponent from "./ZillowMap";

const libraries = ["places", "marker"];
const mapContainerStyle = {
  width: "100%",
  height: "80vh",
};

const SearchResults = () => {
  const [open, setOpen] = React.useState(false);
  const [map, setMap] = useState(null);

  // console.log("searchtext", searchtext);
  const [searchResultsState, setSearchResultsState] = React.useState({
    properties: [],
    loadingSearchResults: true,
    propertyDetailModal: false,
    propertyId: 0,
    mapMarkers: [],
  });
  // console.log("searchResultsState", searchResultsState);
  const anchorRef = React.useRef(null);
  const prevOpen = React.useRef(open);
  const { searchtext } = useParams();
  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyDUSoQttCzEt-f8orS76rlNIqpT6uRIwAY" // Add your API key
  // });

  // const onLoad = useCallback(function callback(map) {
  //   // This is just an example of map instance, you can use it to fit bounds
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map);
  // }, []);

  // const onUnmount = useCallback(function callback(map) {
  //   setMap(null);
  // }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const handlePropertyDetailModal = (property, index) => {
    setSearchResultsState((prev) => ({
      ...prev,
      propertyDetailModal: true,
      propertyId: property?.id,
    }));
  };

  const handlePropertyDetailClose = () => {
    setSearchResultsState((prev) => ({ ...prev, propertyDetailModal: false }));
  };
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      searchString: 94040,
      offset: 0,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("http://localhost:8080/search", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
        let marker = [];
        for (let i = 0; i < result?.data.length; i++) {
          const property = result?.data[i];
          marker.push({
            id: property?.id,
            position: { lat: property?.latitude, lng: property?.longitude },
          });
        }
        console.log("marker array", marker);
        setSearchResultsState((prev) => ({
          ...prev,
          properties: result?.data,
          loadingSearchResults: false,
          mapMarkers: marker,
        }));
      })
      .catch((error) => console.error(error));
  }, []);

  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyDUSoQttCzEt-f8orS76rlNIqpT6uRIwAY",
  //   libraries,
  // });

  // const { AdvancedMarkerElement } =  google.maps.importLibrary("marker");
  // if (loadError) {
  //   return <div>Error loading maps</div>;
  // }

  // if (!isLoaded) {
  //   return <div>Loading maps</div>;
  // }

  const markers = [
    { id: 1, position: { lat: 37.37683, lng: -122.07045 } },
    { id: 2, position: { lat: -3.745, lng: -38.53 } },
    { id: 3, position: { lat: -3.74, lng: -38.523 } },
  ];

  return (
    <>
      {searchResultsState?.loadingSearchResults ? (
        <div className="searchresults-spinner-block">
          <CircularProgress className="searchresults-spinner" />{" "}
        </div>
      ) : (
        <>
          <div className="search-page">
            <div className="search-header">
              <TextField
                className="search-input-field"
                value={searchtext}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <CancelIcon />
                      </IconButton>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                ref={anchorRef}
                id="composition-button"
                className="drowndown-btn"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                variant="outlined"
                endIcon={<KeyboardArrowDownIcon />}
              >
                For Sale
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleClose}>Profile</MenuItem>
                          <MenuItem onClick={handleClose}>My account</MenuItem>
                          <MenuItem onClick={handleClose}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <Button
                endIcon={<KeyboardArrowDownIcon />}
                ref={anchorRef}
                className="drowndown-btn"
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                variant="outlined"
              >
                Price
              </Button>
              <Button
                className="drowndown-btn"
                endIcon={<KeyboardArrowDownIcon />}
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                variant="outlined"
              >
                Beds & Baths
              </Button>
              <Button
                className="drowndown-btn"
                endIcon={<KeyboardArrowDownIcon />}
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                variant="outlined"
              >
                Home Type
              </Button>
              <Button
                className="drowndown-btn"
                endIcon={<KeyboardArrowDownIcon />}
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                variant="outlined"
              >
                More
              </Button>
              <Button
                className="drowndown-btn search-filter-btn"
                variant="contained"
              >
                Save Search
              </Button>
            </div>
            <div className="search-results-block">
              <div className="google-maps">
                <GoogleMapComponent properties={searchResultsState?.properties} handlePropertyDetailModal={handlePropertyDetailModal} />
                {/* <LoadScript googleMapsApiKey="AIzaSyDUSoQttCzEt-f8orS76rlNIqpT6uRIwAY"> */}
                {/* {isLoaded && (
                  <>
                 
                  </>
                )} */}
                {/* </LoadScript> */}
              </div>
              <div className="search-results">
                <div className="search-results-text">
                  <div>
                    <h2 className="search-result-h2">
                      {searchtext &&
                        `${searchtext} Real Estate & Homes For Sale`}
                    </h2>
                  </div>
                  <div className="search-numbers">
                    <p className="property-length">
                      {searchResultsState.properties.length} results
                    </p>
                    <div className="sort-property-div">
                      <p className="sort-property">Sort: Homes for you </p>
                      <KeyboardArrowDownIcon />
                    </div>
                  </div>
                </div>
                <div className="search-result-cards">
                  {searchResultsState.properties.length &&
                    searchResultsState.properties.map((i, index) => (
                      <>
                        <PropertyCard
                          property={i}
                          index={index}
                          key={index}
                          handlePropertyDetailModal={handlePropertyDetailModal}
                        />
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <CustomModal
        open={searchResultsState?.propertyDetailModal}
        onClose={handlePropertyDetailClose}
      >
        <PropertyDescriptionHeader handlePropertyDetailClose={handlePropertyDetailClose} />
        <Description propertyId={searchResultsState?.propertyId} />
      </CustomModal>
    </>
  );
};

export default SearchResults;
