import React from 'react';
import './CustomModal.css';

const CustomModal = ({ open, onClose, children }) => {
  if (!open) return null;

  return (
    <div className="custom-modal-overlay">
      <div className="custom-modal-content">
        {/* <button className="custom-modal-close" onClick={onClose}>×</button> */}
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
