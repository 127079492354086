import React from "react";
import { Box, Button, Grid } from "@mui/material";
import Recommended_Home_img from "../images/Recommended_Home_img.webp";
import "../style/HomeRecommendation.css";

const HomeRecommendation = () => {
  return (
    <Box className="recommendations-container">
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <div item xs={12} md={5} className="text-section">
          <h2 className="title">Get home recommendations</h2>
          <p className="description">
            Sign in for a more personalized experience.
          </p>
          <Button variant="outlined" className="sign-in-button">
            Sign in
          </Button>
        </div>
        <Grid item xs={12} md={5} className="image-section">
          <Box
            component="img"
            src={Recommended_Home_img}
            alt="Recommended Home"
            className="recommended-image"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeRecommendation;
