import React, { useState } from 'react';
import PropertyDescriptionHeader from './PropertyDescriptionHeader';
import Description from './Description';
import SearchResults from './SearchResults';
import CustomModal from '../modal/CustomModal';

const DetailedView = () => {
  const [detailViewState,setDetailViewState ] = useState({
    propertyDetailModal:false,
  })

  const popupClicked = () => {
    setDetailViewState((prev) => ({...prev, propertyDetailModal:true}))
  };

  const handleClose = () => {
    setDetailViewState((prev) => ({...prev, propertyDetailModal:false}))
  };

  return (
    <>
      {/* <button onClick={popupClicked}>Popup</button> */}
      <SearchResults />
      <CustomModal open={detailViewState?.propertyDetailModal} onClose={handleClose}>
        <PropertyDescriptionHeader />
        <Description />
      </CustomModal>
    </>
  );
};

export default DetailedView;
