import { Card, IconButton } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

const PropertyCard = ({ property, index, handlePropertyDetailModal }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    fade: true,
  };
  return (
    <Card
      className="property-card"
      onClick={() => {
        handlePropertyDetailModal(property, index);
      }}
    >
      <Slider {...settings} className="property-image-slider">
        {property.images.map((i, index) => (
          <>
            <img
              src={i?.url}
              key={index}
              alt="property-photo"
              id={i?.photo_id}
              className="card-property-photo"
            />
            <FavoriteBorderIcon className="propertylike-icon" />
            <p className="flex-field-text">{property?.flexfieldtext}</p>
          </>
        ))}
      </Slider>
      <div className="property-texts">
        <div className="price-and-menu-icon">
          <h3 className="property-price">{property.price}</h3>
          <IconButton className="more-icon-btn">
            <MoreHorizOutlinedIcon />
          </IconButton>
        </div>
        <div className="card-property-details">
          <p className="card-property-basic-data">
            {`${property?.beds || 0}bds | ${property?.baths || 0}ba | ${
              property?.area || 0
            }sqft - ${property?.statustext}`}
          </p>
          <p className="card-property-address">{property?.address}</p>
        </div>
      </div>
    </Card>
  );
};

export default PropertyCard;
