import React from "react";
import { Box, Button, IconButton } from "@mui/material";
import {
  FavoriteBorder,
  Share,
  VisibilityOff,
  MoreHoriz,
  ArrowBackIos
} from "@mui/icons-material";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import zillowLogo from "../images/logo003.png";
import "../style/PropertyDescriptionHeader.css";

const PropertyDescriptionHeader = ({handlePropertyDetailClose}) => {
  return (
    <Box className="header-container">
      <Box className="left-section">
        {/* <IconButton>
          <ArrowBackIos />
        </IconButton>
        <span className="back-text">Back to search</span> */}
        <Button onClick={handlePropertyDetailClose} className="property-detail-left-header-item" startIcon={ <ArrowBackIos />} >Back to search</Button>
      </Box>
      <Box className="center-section">
        <img src={zillowLogo} alt="Zillow Logo" className="zillow-logo" />
      </Box>
      <Box className="right-section">
        {/* <Box className="icon-with-text">
          <IconButton>
            <FavoriteBorder />
          </IconButton>
          <span className="hide-on-mobile">Save</span>
        </Box>
        <Box className="icon-with-text hide-on-mobile">
          <IconButton>
            <Share />
          </IconButton>
          <span>Share</span>
        </Box>
        <Box className="icon-with-text">
          <IconButton>
            <NotInterestedIcon/>
          </IconButton>
          <span className="hide-on-mobile">Hide</span>
        </Box>
        <Box className="icon-with-text ">
          <IconButton>
            <MoreHoriz />
          </IconButton>
        </Box> */}
        <Button className="property-detail-right-header-item" startIcon={ <FavoriteBorder />} >Save</Button>
        <Button  className="property-detail-right-header-item" startIcon={  <Share />} >Share</Button>
        <Button className="property-detail-right-header-item" startIcon={   <NotInterestedIcon/>} >Hide</Button>
        <IconButton>
            <MoreHoriz />
          </IconButton>
      </Box>
    </Box>
  );
};

export default PropertyDescriptionHeader;
