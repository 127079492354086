import React from 'react';
import { Card, CardContent, Button, Grid } from '@mui/material';
import '../style/HomeCards.css';
import buyImage from '../images/buyImage.webp';
import sellImage from '../images/sellImage.webp';
import rentImage from '../images/rentImage.webp';

const HomeCards = () => {
  const cards = [
    {
      title: "Buy a home",
      description: "Find your place with an immersive photo experience and the most listings, including things you won’t find anywhere else.",
      buttonText: "Browse homes",
      imgSrc: buyImage
    },
    {
      title: "Sell a home",
      description: "No matter what path you take to sell your home, we can help you navigate a successful sale.",
      buttonText: "See your options",
      imgSrc: sellImage
    },
    {
      title: "Rent a home",
      description: "We’re creating a seamless online experience – from shopping on the largest rental network, to applying, to paying rent.",
      buttonText: "Find rentals",
      imgSrc: rentImage
    }
  ];

  return (
    <Grid container spacing={3} className="cards-container">
      {cards.map((card, index) => (
        <Grid item xs={12} md={4} key={index}>
          <Card className="card">
            <CardContent className="card-content">
              <div className="card-image-container">
                <img src={card.imgSrc} alt={card.title} className="card-image" />
              </div>
              <div className="card-text-container">
                <h4 className="card-title">
                  {card.title}
                </h4>
                <p className="card-description">
                  {card.description}
                </p>
                <Button className="card-button">{card.buttonText}</Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default HomeCards;
