import React from "react";
import { Box, Container, Grid, Link } from "@mui/material";
import "../style/Footer.css";
import appstore from "../images/appstore.svg";
import playstore from "../images/playstore.svg";
import facebook from "../images/facebook.svg";
import tiktok from "../images/tiktok.svg";
import instagram from "../images/instagram.svg";
import zillowIcon from "../images/z_logo.svg";
import dropdownIcon from "../images/dropdown.svg";


const Footer = () => {
  return (
    <Box className="footer-container">
      <Container>
        <div className="footer-heading">
          <h6>About Zillow's Recommendations</h6>
          <p className="footer-text">
            Recommendations are based on your location and search activity, such
            as the homes you've viewed and saved and the filters you've used. We
            use this information to bring similar homes to your attention, so
            you don't miss out.
          </p>
        </div>

        <Grid
          container
          spacing={4}
          justifyContent="center"
          className="footer-links"
        >
          <Grid item className="footer-link-item">
            <Link href="#" className="footer-link">
              Real Estate
              <img
                src={dropdownIcon}
                alt="Dropdown"
                className="dropdown-icon"
              />
            </Link>
          </Grid>
          <Grid item className="footer-link-item">
            <Link href="#" className="footer-link">
              Rentals
              <img
                src={dropdownIcon}
                alt="Dropdown"
                className="dropdown-icon"
              />
            </Link>
          </Grid>
          <Grid item className="footer-link-item">
            <Link href="#" className="footer-link">
              Mortgage Rates
              <img
                src={dropdownIcon}
                alt="Dropdown"
                className="dropdown-icon"
              />
            </Link>
          </Grid>
          <Grid item className="footer-link-item">
            <Link href="#" className="footer-link">
              Browse Homes
              <img
                src={dropdownIcon}
                alt="Dropdown"
                className="dropdown-icon"
              />
            </Link>
          </Grid>
        </Grid>
        <hr className="footer-divider" />

        <Grid
          container
          spacing={2}
          justifyContent="center"
          className="footer-links"
        >
          <Grid item>
            <Link href="#" className="footer-link">
              About
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Zestimates
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Research
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Careers
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Careers - U.S. Privacy Notice
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Careers - Mexico Privacy Notice
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Help
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Advertise
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Fair Housing Guide
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Advocacy
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Terms of use
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Privacy Portal
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Cookie Preference
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Learn AI
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Mobile Apps
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Trulia
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              StreetEasy
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              HotPads
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              Out East
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" className="footer-link">
              ShowingTime
            </Link>
          </Grid>

        </Grid>
        <Grid
            container
            spacing={2}
            justifyContent="center"
            className="Personal-information"
          >
            <br/>
            <Grid item>
              <Link href="#" className="Personal-information-link">
                Do Not Sell or Share My Personal Information
                <span aria-hidden="true"> →</span>
              </Link>
            </Grid>
          </Grid>
        <hr className="footer-divider" />

        <div className="footer-text">
          <p>
            Zillow Group is committed to ensuring digital accessibility for
            individuals with disabilities. We are continuously working to
            improve the accessibility of our
          </p>
          <p>
            web experience for everyone, and we welcome feedback and
            accommodation requests. If you wish to report an issue or seek an
            accommodation,
          </p>
          <p>
            please <span className="link-text">let us know.</span>
          </p>
          <br />
          <p>
            Zillow, Inc. holds real estate brokerage
            <span className="link-text"> licenses</span> in multiple states.
            Zillow (Canada), Inc. holds real estate brokerage
            <span className="link-text"> licenses</span> in multiple provinces.
          </p>
          <p>
            <span className="link-text">
              § 442-H New York Standard Operating Procedures
            </span>
            <br />
            <span className="link-text"> § New York Fair Housing Notice </span>
            <br />
            TREC:
            <span className="link-text">
              Information about brokerage services, Consumer protection notice
              California DRE #1522444
            </span>
          </p>
          <p className="non-link-text">Contact Zillow, Inc. Brokerage</p>
          <br />
          <p>
            For listings in Canada, the trademarks REALTOR®, REALTORS®, and the
            REALTOR® logo are controlled by The Canadian Real Estate Association
            (CREA)
          </p>
          <p>
            and identify real estate professionals who are members of CREA. The
            trademarks MLS®, Multiple Listing Service® and the associated logos
            are
          </p>
          <p>
            owned by CREA and identify the quality of services provided by real
            estate professionals who are members of CREA. Used under license.
          </p>
        </div>

        <div className="footer-apps">
          <img
            src={appstore}
            alt="Download on the App Store"
            className="footer-app-icon"
          />
          <img
            src={playstore}
            alt="Get it on Google Play"
            className="footer-app-icon"
          />
        </div>
        <div className="footer-social">
          <div className="footer-logo">
            <Link href="#" className="footer-social-link">
              <img src={zillowIcon} alt="zillow" />
            </Link>
          </div>
          <div className="footer-social-links">
            <span className="follow-us">Follow us:</span>
            <Link href="#" className="footer-social-link">
              <img src={facebook} alt="Facebook" />
            </Link>
            <Link href="#" className="footer-social-link">
              <img src={instagram} alt="Instagram" />
            </Link>
            <Link href="#" className="footer-social-link">
              <img src={tiktok} alt="TikTok" />
            </Link>
            <span className="follow-us">© 2006-2024 Zillow</span>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default Footer;
