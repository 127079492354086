import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useTheme } from "@mui/material/styles";
import searchIcon from "../images/searchIcon.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from 'react-router-dom';

const HomepageBanner = () => {
    const [homepageBannerState, setHomepageBannerState] = useState({
      searchString:""
    })
    const navigate = useNavigate()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleSearch = async (searchString, e) => {
    if (e?.key && e?.key === "Enter") {
      
      navigate(`/homes/${searchString}`)
    }
    if (!e?.key) {
      navigate(`/homes/${searchString}`)
      
    }
  }
  const handleSearchInput = (event) => {
    const {name, value} = event?.target;
    if (name === "search-input") {
      
      setHomepageBannerState((prev) => ({...prev, searchString:value}))
    }
  }
  return (
    <Box className="content-section">
    <Box className="content-text">
      <h1>
        Agents. Tours.
        <br />
        Loans. Homes.
      </h1>
      <Box className="search-bar">
        <input
          type="text"
          placeholder={
            isMobile
              ? "Address, neighborhood, city, ZIP"
              : "Enter an address, neighborhood, city, or ZIP code"
          }
          className="search-input"
          
          value={homepageBannerState?.searchString}
          name="search-input"
          onChange={(e) => handleSearchInput(e)}
          onKeyDown={(e) =>handleSearch(homepageBannerState?.searchString, e)}
        />
        <img onClick={(e) => handleSearch(homepageBannerState?.searchString)} src={searchIcon} alt="Search Icon" className="search-icon" />
      </Box>
    </Box>
  </Box>
  )
}

export default HomepageBanner