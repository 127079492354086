// import logo from './logo.svg';
import "./App.css";
import HomeCards from "./components/HomeCards";
import HomeRecommendation from "./components/HomeRecommendation";
import Footer from "./components/Footer";
import Header from "./components/Header";
import DetailedView from "./components/DetailedView";
import { Route, Routes } from "react-router-dom";
import HomepageBanner from "./components/HomepageBanner";


function App() {
  return (
    <>
    
    <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
            <HomepageBanner/>
              <HomeRecommendation />
              <HomeCards />
            </>
          }
        />
        <Route path="/homes/:searchtext" element={<DetailedView />} />
      </Routes>
      
      <Footer />
    </>
  );
}

export default App;
