import React, { useState } from "react";
import "../style/fact-and-features.css";
import { Button } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const FactsAndFeatures = ({ propertyDetails }) => {
    const [factsAndFeaturesState, setFactsAndFeaturesState] = useState({
        expandDetails: false
    })

    const handleExpand = () => {
        setFactsAndFeaturesState((prev) => ({...prev, expandDetails: !prev?.expandDetails}))
    }
    
  return (
    <>
      <div className={`facts-and-featues-main ${factsAndFeaturesState?.expandDetails ? 'facts-and-featues-show' : 'facts-and-featues-hide'}`}>
        <h2 className="facts-and-features">Facts & features</h2>

        <h3 className="facts-and-features-title">Interior</h3>
        <div className="facts-and-features--list">
          <div className="facts-and-features--sub-list">
            <h4>Bedrooms & bathrooms</h4>
            <ul className="facts-and-features--ul">
              <li>Bedrooms:{propertyDetails?.bathrooms}</li>
              <li>Bathrooms: {propertyDetails?.bedrooms}</li>
              <li>Full bathrooms:{propertyDetails?.full_bathrooms}</li>
              {propertyDetails?.half_bathrooms && (
                <li>1/2 bathrooms:{propertyDetails?.half_bathrooms}</li>
              )}
            </ul>
          </div>
          <div className="facts-and-features--sub-list">
            <h4>Kitchen</h4>
            <ul className="facts-and-features--ul">
              {Array.isArray(propertyDetails?.features_kitchen) &&
                Array(propertyDetails?.features_kitchen).length && (
                  <li>
                    {Array(propertyDetails?.features_kitchen).map(
                      (i, index) => (
                        <span key={index}>{`${i}, ${" "} `}</span>
                      )
                    )}
                  </li>
                )}
              {/* <li>Bedrooms:{propertyDetails?.bathrooms}</li> */}
            </ul>
          </div>
          <div className="facts-and-features--sub-list">
            <h4>Heating</h4>
            <ul className="facts-and-features--ul">
              {Array.isArray(propertyDetails?.feature_heating) &&
                Array(propertyDetails?.feature_heating).length && (
                  <li>
                    {Array(propertyDetails?.feature_heating).map((i, index) => (
                      <span key={index}>{`${i}, ` + " "}</span>
                    ))}
                  </li>
                )}
            </ul>
          </div>
          <div className="facts-and-features--sub-list">
            <h4>Cooling</h4>
            <ul className="facts-and-features--ul">
              {Array.isArray(propertyDetails?.feature_cooling) &&
                Array(propertyDetails?.feature_cooling).length && (
                  <li>
                    {Array(propertyDetails?.feature_cooling).map((i, index) => (
                      <span key={index}>{`${i}, ` + " "} </span>
                    ))}
                  </li>
                )}
            </ul>
          </div>
          <div className="facts-and-features--sub-list">
            <h4>Appliances</h4>
            <ul className="facts-and-features--ul">
              {Array.isArray(propertyDetails?.appliances_included) &&
                Array(propertyDetails?.appliances_included).length && (
                  <li>
                    {" "}
                    Included:
                    {Array(propertyDetails?.appliances_included).map(
                      (i, index) => (
                        <span key={index}>{`${i}, ` + " "} </span>
                      )
                    )}
                  </li>
                )}
              {Array.isArray(propertyDetails?.appliances_laundry) &&
                Array(propertyDetails?.appliances_laundry).length && (
                  <li>
                    {" "}
                    Laundry:
                    {Array(propertyDetails?.appliances_laundry).map(
                      (i, index) => (
                        <span key={index}>{`${i}, ` + " "} </span>
                      )
                    )}
                  </li>
                )}
            </ul>
          </div>

          <div className="facts-and-features--sub-list">
            <h4>Features</h4>
            <ul className="facts-and-features--ul">
              {Array.isArray(propertyDetails?.features_features_flooring) &&
                Array(propertyDetails?.features_features_flooring).length && (
                  <li>
                    {" "}
                    Floorings:
                    {Array(propertyDetails?.features_features_flooring).map(
                      (i, index) => (
                        <span key={index}>{`${i}, ` + " "} </span>
                      )
                    )}
                  </li>
                )}
              {Array.isArray(
                propertyDetails?.features_features_fireplacesfeatures
              ) &&
                Array(propertyDetails?.features_features_fireplacesfeatures)
                  .length && (
                  <li>
                    {" "}
                    Fireplaces:
                    {Array(
                      propertyDetails?.features_features_fireplacesfeatures
                    ).map((i, index) => (
                      <span key={index}>{`${i}, ` + " "} </span>
                    ))}
                  </li>
                )}
              {propertyDetails?.features_features_numberoffireplaces && (
                <li>
                  {" "}
                  Number of Fireplaces:
                  {propertyDetails?.features_features_numberoffireplaces}
                </li>
              )}
            </ul>
          </div>

          <div className="facts-and-features--sub-list">
            <h4>Interior Area</h4>
            <ul className="facts-and-features--ul">
              {propertyDetails?.structure_area && (
                <li>
                  Total structure area:
                  {propertyDetails?.structure_area}
                </li>
              )}
              {propertyDetails?.interior_livable_area && (
                <li>
                  Total structure area:
                  {propertyDetails?.interior_livable_area} sqft
                </li>
              )}
            </ul>
          </div>
        </div>

        <h3 className="facts-and-features-title">Property</h3>
        <div className="facts-and-features--list">
          <div className="facts-and-features--sub-list">
            <h4>Parking</h4>
            <ul className="facts-and-features--ul">
              {propertyDetails?.parking_space && (
                <li> Total Spaces: {propertyDetails?.parking_space}</li>
              )}
              {propertyDetails?.parking_features && (
                <li> Parking features: {propertyDetails?.parking_features}</li>
              )}
              {propertyDetails?.garage_space && (
                <li> Garage spaces: {propertyDetails?.garage_space}</li>
              )}
            </ul>
          </div>
          <div className="facts-and-features--sub-list">
            <h4>Details</h4>
            <ul className="facts-and-features--ul">
              {propertyDetails?.parcel_number && (
                <li> Parcel Number: {propertyDetails?.parcel_number}</li>
              )}
              {/* {propertyDetails?.property_details_special_conditions && (
                <li> Special Conditions: {propertyDetails?.property_details_special_conditions}</li>
              )} */}
              {Array.isArray(
                propertyDetails?.property_details_special_conditions
              ) &&
                Array(propertyDetails?.property_details_special_conditions)
                  .length && (
                  <li>
                    Details:
                    {Array(
                      propertyDetails?.property_details_special_conditions
                    ).map((i, index) => (
                      <span key={index}>{`${i}, ` + " "} </span>
                    ))}
                  </li>
                )}
              {propertyDetails?.property_details_zoning && (
                <li> Zoning: {propertyDetails?.property_details_zoning}</li>
              )}
            </ul>
          </div>
          <div className="facts-and-features--sub-list">
            <h4>Features</h4>
            <ul className="facts-and-features--ul">
              {Array.isArray(propertyDetails?.pool_features) &&
                Array(propertyDetails?.pool_features).length && (
                  <li>
                    Pool features:
                    {Array(propertyDetails?.pool_features).map((i, index) => (
                      <span key={index}>{`${i}, ` + " "} </span>
                    ))}
                  </li>
                )}
            </ul>
          </div>
        </div>
        <h3 className="facts-and-features-title">Construction</h3>
        <div className="facts-and-features--list">
          <div className="facts-and-features--sub-list">
            <h4>Condition</h4>
            <ul className="facts-and-features--ul">
              {propertyDetails?.new_construction && (
                <li>New construction: {propertyDetails?.new_construction}</li>
              )}
              {propertyDetails?.year_built && (
                <li>Year built: {propertyDetails?.year_built}</li>
              )}
            </ul>
          </div>
          <div className="facts-and-features--sub-list">
            <h4>Type & style</h4>
            <ul className="facts-and-features--ul">
              {propertyDetails?.hometype && (
                <li>Home Type: {propertyDetails?.hometype}</li>
              )}
              {/* {propertyDetails?.year_built && (
                <li>Year built: {propertyDetails?.year_built}</li>
              )} */}
            </ul>
          </div>
        </div>
        <h3 className="facts-and-features-title">Utilities & green energy</h3>
        <div className="facts-and-features--list">
          <div className="facts-and-features--sub-list">
            {/* <h4>Condition</h4> */}
            <ul className="facts-and-features--ul">
              {Array.isArray(propertyDetails?.utilities_gas) &&
                Array(propertyDetails?.utilities_gas).length && (
                  <li>
                    Gas:
                    {Array(propertyDetails?.utilities_gas).map((i, index) => (
                      <span key={index}>{`${i}, ` + " "} </span>
                    ))}
                  </li>
                )}
              {Array.isArray(propertyDetails?.utilities_sewer) &&
                Array(propertyDetails?.utilities_sewer).length && (
                  <li>
                    Sewer:
                    {Array(propertyDetails?.utilities_sewer).map((i, index) => (
                      <span key={index}>{`${i}, ` + " "} </span>
                    ))}
                  </li>
                )}
              {Array.isArray(propertyDetails?.utilities_water) &&
                Array(propertyDetails?.utilities_water).length && (
                  <li>
                    Water:
                    {Array(propertyDetails?.utilities_water).map((i, index) => (
                      <span key={index}>{`${i}, ` + " "} </span>
                    ))}
                  </li>
                )}
              {Array.isArray(propertyDetails?.utilities_for_property) &&
                Array(propertyDetails?.utilities_for_property).length && (
                  <li>
                    Utilities for property:
                    {Array(propertyDetails?.utilities_for_property).map((i, index) => (
                      <span key={index}>{`${i}, ` + " "} </span>
                    ))}
                  </li>
                )}
            </ul>
          </div>
        </div>
        <h3 className="facts-and-features-title">Community & HOA</h3>
        <h3 className="facts-and-features-title">
          Financial & listing details
        </h3>
      </div>
      {!factsAndFeaturesState?.expandDetails && <div className="facts-and-features-bottom-shodow"></div>}
      <Button onClick={() => handleExpand()} startIcon={factsAndFeaturesState?.expandDetails? <KeyboardArrowUpIcon />: <KeyboardArrowDownIcon />} >{factsAndFeaturesState?.expandDetails ? "Hide" : "Show"}</Button>
    </>
  );
};

export default FactsAndFeatures;
