import React, { useCallback, useState } from "react";
import {
  AdvancedMarker,
  APIProvider,
  InfoWindow,
  Map,
  Pin,
} from "@vis.gl/react-google-maps";
import "../style/maps.css";
import PropertyCard from "./PropertyCard";
import img1 from "../images/logo003.png";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const libraries = ["places", "maps"];
const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

const locations = [
  { lat: 40.748817, lng: -73.985428, label: "Location 1" },
  { lat: 40.758817, lng: -73.985428, label: "Location 2" },
  // Add more locations here
];

const GoogleMapComponent = ({ properties, handlePropertyDetailModal }) => {
  const [infoWindowIndex, setInfoWindowIndex] = useState(null);
  console.log("properties", properties);

  const handleMarkerClick = useCallback((index) => {
    setInfoWindowIndex((prevIndex) => (prevIndex === index ? null : index));
  }, []);

  const handleClose = useCallback(() => setInfoWindowIndex(null), []);

  return (
    <APIProvider
      apiKey={"AIzaSyDUSoQttCzEt-f8orS76rlNIqpT6uRIwAY"}
      libraries={libraries}
    >
      <Map
        defaultZoom={14}
        mapId={"971633b48f992da4"}
        defaultCenter={{
          lat: properties[0]?.latitude,
          lng: properties[0].longitude,
        }}
        streetViewControl={false}
        // mapTypeControl={false}

        defaultBounds={{
          east: -122.05831,
          north: 37.412765,
          south: 37.35676,
          west: -122.117852,
        }}
      >
        {properties.map((position, index) => {
          const propertyiconOnMap = document.createElement("div");
          propertyiconOnMap.className = "property-icon-on-map";
          propertyiconOnMap.textContent = position?.price;
          propertyiconOnMap.addEventListener("click", () =>  handleMarkerClick(index))
          return (
            <AdvancedMarker
              clickable={true}
              position={{ lat: position?.latitude, lng: position?.longitude }}
              key={index}
              onClick={() => handleMarkerClick(index)}
            collisionBehavior="REQUIRED_AND_HIDES_OPTIONAL"
            >
              <Pin
                glyph={propertyiconOnMap}
                scale={1}
                background={"none"}
                borderColor={"#a3000b"}
                glyphColor={"#a3000b"} 
              />
              {infoWindowIndex === index && (
                <InfoWindow
                  anchor={{ lat: position?.latitude, lng: position?.longitude }}
                  onClose={handleClose}
                  position={{
                    lat: position?.latitude,
                    lng: position?.longitude,
                  }}
                  className="search-results-infowindow"
                >
                  <PropertyCard
                    property={position}
                    index={index}
                    handlePropertyDetailModal={handlePropertyDetailModal}
                  />
                </InfoWindow>
              )}
            </AdvancedMarker>
          );
        })}
      </Map>
    </APIProvider>
  );
};

export default GoogleMapComponent;
