import {
  Autocomplete,
  Button,
  TextField,
  Chip,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AdvancedMarker,
  APIProvider,
  InfoWindow,
  Map,
  MapControl,
  Pin,
} from "@vis.gl/react-google-maps";
import home from '../images/home.svg'
const ContactAgent = ({propertyData}) => {
  const [contactAgentState, setContactAgentState] = useState({
    searchfield: "",
    selectedKeywords: [],
    searchResults: [],
  });
  console.log("propertyData", propertyData);
  
  const { searchtext } = useParams();

  const [keywordData, setKeywordData] = useState([
    { label: "Los Altos" },
    { label: "Sunnyvale" },
    { label: "Mountain View" },
    { label: "Palo Alto" },
    { label: "Stanford" },
    { label: "Menlo Park" },
    { label: "Silicon Valley" },
    { label: "San Francisco" },
    { label: "San Jose" },
    { label: "Sunny Days" },
    { label: "Rainfall" },
    { label: "Humidity" },
    { label: "Temperature" },
    { label: "Snowfall" },
    { label: "Wind Speed" },
    { label: "Storms" },
    { label: "Climate" },

    // School-related keywords
    { label: "School District" },
    { label: "Public Schools" },
    { label: "Private Schools" },
    { label: "High Schools" },
    { label: "Middle Schools" },
    { label: "Elementary Schools" },
    { label: "School Ratings" },
    { label: "School Proximity" },

    // Crime Rate-related keywords
    { label: "Crime Statistics" },
    { label: "Safety Index" },
    { label: "Violent Crime" },
    { label: "Property Crime" },
    { label: "Police Presence" },
    { label: "Neighborhood Safety" },
    { label: "Crime Trends" },
    { label: "Crime Reports" },

    // Traffic-related keywords
    { label: "Traffic Congestion" },
    { label: "Commute Time" },
    { label: "Road Conditions" },
    { label: "Traffic Accidents" },
    { label: "Traffic Signals" },
    { label: "Parking Availability" },
    { label: "Speed Limits" },
    { label: "Traffic Flow" },

    // Public Transportation-related keywords
    { label: "Bus Routes" },
    { label: "Train Stations" },
    { label: "Subway Access" },
    { label: "Transit Schedules" },
    { label: "Public Transit Cost" },
    { label: "Bike Lanes" },
    { label: "Walkability" },
    { label: "Transit Reliability" },
  ]);
const [infoWindowIndex, setInfoWindowIndex]=useState()
  const [open, setOpen] = useState(false); // Local state to control dropdown visibility

  const handleSearchFieldChange = (e, newValue) => {
    const searchQuery = newValue || e.target.value;
    setContactAgentState((prevState) => ({
      ...prevState,
      searchfield: searchQuery,
    }));

    // Open the dropdown when there's a search query
    if (searchQuery) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleKeywordSelect = (event, newValue) => {
    if (
      newValue &&
      !contactAgentState.selectedKeywords.includes(newValue.label)
    ) {
      setContactAgentState((prevState) => ({
        ...prevState,
        selectedKeywords: [...prevState.selectedKeywords, newValue.label],
        searchfield: "", // Clear search field after selection
      }));
      setOpen(false); // Close dropdown after selection
    }
  };

  const handleKeywordDelete = (keywordToDelete) => () => {
    setContactAgentState((prevState) => ({
      ...prevState,
      selectedKeywords: prevState.selectedKeywords.filter(
        (keyword) => keyword !== keywordToDelete
      ),
      searchfield: "", // Clear search field after deletion
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const newTag = contactAgentState.searchfield.trim();
      if (
        newTag &&
        !keywordData.some(
          (keyword) => keyword.label.toLowerCase() === newTag.toLowerCase()
        )
      ) {
        setKeywordData((prevData) => [...prevData, { label: newTag }]);
        setContactAgentState((prevState) => ({
          ...prevState,
          selectedKeywords: [...prevState.selectedKeywords, newTag],
          searchfield: "", // Clear search field after adding new tag
        }));
        fetchExperts(searchtext, contactAgentState?.searchfield);
        setOpen(false); // Close dropdown after adding new tag
      }
      e.preventDefault(); // Prevent form submission or other default actions
    }
  };

  const fetchExperts = (zipcode, searchQuery) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      zipcode: zipcode,
      skill: searchQuery,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("http://localhost:8080/search/experts", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
        setContactAgentState((prevState) => ({
          ...prevState,
          searchResults: result.data.map((expert) => ({
            uuid: expert?.uid,
            label: expert?.displayName,
            skill: expert?.skill || "",
            zipcode: expert?.zipcode || "",
            lat: expert?.lat,
            long: expert?.lng,
            cot_score: expert?.cotScore,
            cot_member: expert?.cotMember,
          })),
          // setContactAgentState((prevState) => ({
          //   ...prevState,
          //   searchResults: result.data.map((expert) => ({
          //     uuid: expert.uuid,
          //     label: expert.displayname,
          //     skill: expert.skill,
          //     zipcode: expert.zipcode,
          //     lat: expert.lat,
          //     long: expert.long,
          //     cot_score: expert.cot_score,
          //     cot_member: expert.cot_member,
          //   })),
        }));
      })
      .catch((error) => console.error(error));
  };

  console.log("setContactAgentState", contactAgentState);

  const options = contactAgentState.searchfield
    ? keywordData.filter((location) =>
        location.label
          .toLowerCase()
          .includes(contactAgentState.searchfield.toLowerCase())
      )
    : [];

  const handleContactHandleAgentBtn = (e) => {
    // fetchExperts(searchtext, contactAgentState?.searchfield)

    const newTag = contactAgentState.searchfield.trim();
    if (
      newTag &&
      !keywordData.some(
        (keyword) => keyword.label.toLowerCase() === newTag.toLowerCase()
      )
    ) {
      setKeywordData((prevData) => [...prevData, { label: newTag }]);
      setContactAgentState((prevState) => ({
        ...prevState,
        selectedKeywords: [...prevState.selectedKeywords, newTag],
        searchfield: "", // Clear search field after adding new tag
      }));
      fetchExperts(searchtext, contactAgentState?.searchfield);
      setOpen(false); // Close dropdown after adding new tag
    }
    e.preventDefault(); // Prevent form submission or other default actions
  };
  console.log(
    "contactAgentState.searchResults",
    contactAgentState.searchResults
  );
  const homeicon = document.createElement("img");
  homeicon.src = home
  homeicon.alt="home"
  homeicon.className='property-icon'
  const handleMarkerClick = useCallback((index) => {
    setInfoWindowIndex((prevIndex) => (prevIndex === index ? null : index));
  }, []);
  const handleClose = useCallback(() => setInfoWindowIndex(null), []);

  return (
    <div className="contact-agent-main">
      <div className="contact-agent">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          open={open && options.length > 0}
          closeText="asdasd"
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          options={options}
          getOptionLabel={(option) => option.label || ""}
          onChange={handleKeywordSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Please enter your query for expert"
              placeholder="Search Expert"
              onChange={(e) => handleSearchFieldChange(e, e.target.value)}
              onKeyDown={handleKeyDown}
              value={contactAgentState.searchfield}
            />
          )}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.label}
            </Box>
          )}
          noOptionsText=""
        />
        <div>
          {contactAgentState.selectedKeywords.map((keyword, index) => (
            <Chip
              key={index}
              label={keyword}
              color="primary"
              onDelete={handleKeywordDelete(keyword)}
            />
          ))}
        </div>
        <Button
          variant="outlined"
          className="contact-agent-button"
          onClick={(e) => {
            handleContactHandleAgentBtn(e);
          }}
        >
          Contact Expert
        </Button>
        {contactAgentState.searchResults.length && (
          <>
            <div className="experts-map">
              <APIProvider apiKey={"AIzaSyDUSoQttCzEt-f8orS76rlNIqpT6uRIwAY"}>
                <Map
                  defaultZoom={50}
                  mapId={"971633b48f992da4"}
                  defaultCenter={{
                    lat: propertyData?.latitude,
                    lng: propertyData?.longitude,
                  }}
                >
                  
                  {contactAgentState.searchResults.map((position, index) => {
                      const priceTag = document.createElement("div");

                      priceTag.className = "price-tag";
                      priceTag.textContent = index + 1;
                      
                    return (
                      <>
                        {(Boolean(position?.lat) || Boolean(position?.long)) && (
                          <AdvancedMarker
                          clickable
                            position={{
                              lat: position?.lat,
                              lng: position?.long,
                            }}
                            key={index}
                            onClick={() => handleMarkerClick(index)
                            }
                          >
                            <Pin
                              glyph={priceTag}
                              scale={1}
                              background={"none"}
                              borderColor={"#a3000b"}
                              glyphColor={"#a3000b"} 
                            />
                               {infoWindowIndex === index && (
                <InfoWindow
                  anchor={{ lat: position?.lat, lng: position?.long }}
                  onClose={handleClose}
                  position={{
                    lat: position?.lat,
                    lng: position?.long,
                  }}
                  className="expert-infowindow"
                >
                  <div className="expert-info-window" >
                    <p className="expert-info-window-name">Name: {position?.label}</p>
                    <p className="expert-info-window-score">Score: {position?.cot_score}</p>
                    <p className="expert-info-window-member">Member: {position?.cot_member ? "Yes": "No"}</p>
                  </div>
                </InfoWindow>
              )}
                          </AdvancedMarker>
                        )}
                      </>
                    );
                  })}
                <AdvancedMarker
                            position={{
                              lat: propertyData?.latitude,
                              lng: propertyData?.longitude,
                            }}
                            // key={index}
                          >
                            <Pin
                              glyph={homeicon}
                              background={"none"}
                              borderColor={"none"}
                              glyphColor={"none"}
                            />
                          </AdvancedMarker>
                </Map>
              </APIProvider>
            </div>
          </>
        )}
        <div className="search-experts-results">
          {contactAgentState.searchResults
            .sort((a, b) => b.cot_score - a.cot_score)
            .map((expert, index) => (
              <Accordion key={expert.uuid}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <span>
                    {index + 1} {"--> "}{" "}
                  </span>{" "}
                  {expert.label}
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Typography variant="body2">Skill: {expert.skill}</Typography> */}
                  <Typography variant="body2">
                    Cot score: {expert.cot_score}
                  </Typography>
                  <Typography variant="body2">
                    COT Member: {expert.cot_member ? "Yes" : "No"}
                  </Typography>
                  <Button className="contact-agent-button">Connect Now</Button>
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ContactAgent;
