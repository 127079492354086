import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(() => ({

  paddingRight20: { paddingRight: "20px" },
  fontSize9: {
    fontSize: "9px"
  },
  fontSize14: {
    fontSize: "14px"
  },

  width120:{
    width:"120%"
  },

  blueColor:{
    color:"rgb(13, 69, 153)"
  },

  sellOrShare:{
    textAlign:"center",
    color:"rgb(13, 69, 153)"

  },

  layoutActionBar: {
    position: "sticky",
    zIndex: 999,
    top: 0
  },

  svg: {
    flexShrink: "0",
    height: "22px",
    width: "22px",
  },
  heading: {
    textAlign: "center",
  },
  menuButtons: {
    fontSize: "15px",
    color: "#000"
  },
  appBar: {
    backgroundColor: "white"
  },
  contentContainer: {
    padding: "20px",
  },
  containerStyle: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    gap: "36px"
  },
  containerInfo: {
    width: "69.5%",
  },
  contentContainerButtonMain: {
    top: "129px",
    position: "sticky",
    border: "1px solid rgb(209, 209, 213)",
    borderRadius: "5px",
    padding: "15px"
  },
  contentContainerButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"

  },

  rightSideButton1: {
    marginBottom: "10px",
    backgroundColor: "rgb(0, 106, 255)",
    '&:hover': {
      backgroundColor: "rgb(0, 106, 255)",
    },
    color: "rgb(255, 255, 255)",
    padding: "0px 12px",
    borderRadius: "5px",
    border: "1px solid rgb(0, 106, 255)",
    display: "flex",
    flexDirection: "column",
    textTransform: "none",
    height: "50px"
  },
  rightSideButton2: {
    marginBottom: "10px",
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgb(0, 106, 255)",
    padding: "5px 10px",
    borderRadius: "5px",
    border: "1px solid rgb(0, 106, 255)",
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    textTransform: "none",
  },

  quickFacts: {
    display: "flex", justifyContent: "space-between"
  },
  maxWidth90: { maxWidth: "90%" },
  marginTop8: { marginTop: "8px" },
  title: {
    fontSize: '24px',
    color: 'blue',
  },
  preQualified: { fontSize: "15px" },
  qualities: {
    backgroundColor: "rgb(246, 246, 250)", alignItems: "center", borderRadius: "4px", display: "flex", overflow: "hidden", minWidth: "50px", width: "100%", padding: "12px",
    margin: "6px"
  },
  horizontalLine: {
    color: "rgb(209, 209, 213)",
    padding: "0px 8px"
  },
  dlData: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    margin: "16px 0px",
    fontSize: "16px"
  },
  showMoreButton: {
    color: "rgb(13,69,153)",
    textDecoration: "none",
    cursor: "pointer",
    textTransform: "none",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    display: "inline-flex",
    boxShadow: "none",
    border: "0px",
    background: "none",
    padding: "0px",
    appearance: "none"
  },
  textStyle: {
    textTransform: "none",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    margin: "0px"
  },
  listingAndSourceData: {
    margin: "8px 0px"

  },
  listingText: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "flex-start"
  },
  listedBy: {
    display: "flex",
    marginTop: "8px"
  },
  listedInfo: {
    display: "flex",
    flexWrap: "wrap"
  },
  paddingRight3: {
    paddingRight: "3px"
  },
  colorLinks:{
    color: "rgb(13,69,153)"
  },
  legalProtections:{
    display:"flex"
  },
  footerContent:{
    padding:"20px",
    fontSize:"10px",
    textAlign:"center",
  },
  footerParagraph:{
    textAlign:"center",
    padding:"0% 15%"
  }
}));

export default useStyles;
