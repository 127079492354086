import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import zillowLogo from "../images/logo003.png";
import searchIcon from "../images/searchIcon.svg";
import "../style/Header.css";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [headerState, setHeaderState] = useState({
    searchString:""
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navLinks = [
    "Buy",
    "Rent",
    "Sell",
    "Home Loans",
    "Find an Agent",
    "Manage Rentals",
    "Advertise",
    "Help",
    "Sign In",
  ];

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleScroll = () => {
    const contentSection = document.querySelector(".content-section");
    if (contentSection) {
      setSticky(window.scrollY > contentSection.offsetHeight);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    
  }, []);
  // useEffect(()=> {
    
  // }, [headerState?.searchString])
  const handleSearch = async (searchString, e) => {
    if (e.key && e.key === "Enter") {
      
      navigate(`/homes/${searchString}`)
    }
    if (!e.key) {
      navigate(`/homes/${searchString}`)
      
    }
  }
const handleSearchInput = (event) => {
  const {name, value} = event?.target;
  if (name === "search-input") {
    
    setHeaderState((prev) => ({...prev, searchString:value}))
  }
}

  return (
    <Box className="header-container-main">
      <AppBar position="static" className="custom-appbar">
        <Toolbar className="menubar">
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                className="menu-icon-button"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon className="menu-icon" />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                classes={{ paper: "drawer-paper" }}
              >
                <Box className="drawer-header">
                  <IconButton
                    onClick={toggleDrawer(false)}
                    className="close-icon-button"
                  >
                    <CloseIcon className="close-icon" />
                  </IconButton>
                  <Box className="drawer-logo-container">
                    <img
                      src={zillowLogo}
                      alt="Zillow Logo"
                      className="drawer-logo"
                    />
                  </Box>
                </Box>
                <List>
                  {navLinks.map((link) => (
                    <ListItem button key={link}>
                      <ListItemText primary={link} />
                    </ListItem>
                  ))}
                </List>
              </Drawer>
              <Box className="logo-container">
                <img src={zillowLogo} alt="Zillow Logo" className="logo" />
              </Box>
            </>
          ) : (
            <>
              <Box className="nav-links-left">
                {navLinks.slice(0, 5).map((link) => (
                  <span
                    key={link}
                    className="nav-link"
                    onClick={toggleDrawer(false)}
                  >
                    {link}
                  </span>
                ))}
              </Box>
              <Box className="logo-container">
                <img src={zillowLogo} alt="Zillow Logo" className="logo" />
              </Box>
              <Box className="nav-links-right">
                {navLinks.slice(5).map((link) => (
                  <span
                    key={link}
                    className="nav-link"
                    onClick={toggleDrawer(false)}
                  >
                    {link}
                  </span>
                ))}
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>

      {/* Sticky Header with logo and Search bar */}
      <Box className={`sticky-header ${isSticky ? "visible" : ""}`}>
        <Box className="sticky-content">
          {!isMobile && (
            <Box className="sticky-logo-container">
              <img src={zillowLogo} alt="Zillow Logo" className="sticky-logo" />
            </Box>
          )}
          <Box className="search-bar">
            <input
              type="text"
              placeholder={
                isMobile
                  ? "Address, neighborhood, city, ZIP"
                  : "Enter an address, neighborhood, city, or ZIP code"
              }
              value={headerState?.searchString}
              name="search-input"
              onChange={(e) => handleSearchInput(e)}
              onKeyDown={(e) =>handleSearch(headerState?.searchString, e)}
      
              className="search-input"
            />
            <img src={searchIcon} alt="Search Icon" className="search-icon" onClick={(e) => handleSearch(headerState?.searchString)} />
          </Box>
        </Box>
      </Box>

      {/* Content Section */}
 
    </Box>
  );
};

export default Header;
